<template>
  <v-container fluid>
    <v-overlay absolute>
      <div
        class="accent pa-6 headline rounded"
        v-text="message"
      />
    </v-overlay>
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="ma-5 text-center"
      v-html="currentPlayer.tangram"
    />
    <!-- eslint-enable vue/no-v-html -->
  </v-container>
</template>

<script>
export default {
  name: 'FinishedContainer',
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentPlayer () {
      return this.game.playerList.find(player => player.id === this.playerId)
    },
    duration () {
      const { startPlayingTime, stopPlayingTime } = this.currentPlayer
      return Math.round((stopPlayingTime - startPlayingTime) / 1000)
    },
    message () {
      if (!this.duration) {
        return this.$t('networkdiagram.finished.noPlayed')
      }
      const minutes = Math.trunc(this.duration / 60)
      const seconds = this.duration % 60
      return this.$tc('networkdiagram.finished.timePlayed', minutes, { minutes, seconds })
    },
  },
}
</script>

<style lang="scss">
  #tangram {
    max-height: calc(100vh - 64px - 71px)
  }
</style>
